<template>
  <b-modal
      id="modal-create-personalization-entry"
      ref="modal-create-personalization-entry"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.createPersonalizationEntry.title') }}</h3>
    </template>

    <template #default>
      <div>
        <div class="mt-4 mx-5 mb-3">
          <label for="value-name">
            {{ $t('modals.createPersonalizationEntry.name') }}
          </label>
          <b-form-input
              id="entry-name"
              v-model="name"
              autofocus
              debounce="500"
              @keyup.enter="enterPressed"
              @update="nameUpdated"
          >
          </b-form-input>
        </div>
        <div class="mx-5 mb-4">
          <label for="value-id">
            {{ $t('modals.createPersonalizationEntry.id') }}
          </label>
          <b-form-input
              id="entry-id"
              v-model="id"
              debounce="500"
              @input="idChangedByUser"
              @keyup.enter="enterPressed"
          >
          </b-form-input>
          <small
              class="font-italic d-block form-text text-muted"
              v-html="$t('modals.createPersonalizationEntry.idDesc')">
          </small>
        </div>
      </div>
    </template>

    <template #modal-footer>
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="closeModal"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          :disabled="!isPersonalizationEntryName"
          variant="primary"
          class="p-3"
          block
          @click="createPersonalizationEntry"
      >
        {{ $t('modals.createPersonalizationSet.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import GetPersonalizedVariableValues from '@/graphQlQueries/queries/getPersonalizedVariableValues';
import slugify from '@sindresorhus/slugify';

export default {
  name: 'ModalCreateNewPersonalizationEntry',
  props: {
    setId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    name: null,
    id: null,
    idChangedManually: false,
    clientKeys: [],
  }),
  computed: {
    isPersonalizationEntryName() {
      return !!this.name && !!this.id;
    },
  },
  mounted() {
    this.$refs['modal-create-personalization-entry'].show();
  },
  apollo: {
    clientKeys: {
      query: GetPersonalizedVariableValues,
      variables() {
        return {
          setId: this.setId,
        };
      },
      update(data) {
        return data.getPersonalizedVariableValues.map(
          (contact) => contact.client_key,
        ).filter((value) => value);
      },
      skip() {
        return !this.setId;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    createPersonalizationEntry() {
      this.$emit(
        'create-personalization-entry',
        { name: this.name, id: this.id },
      );
      this.$refs['modal-create-personalization-entry'].hide();
    },
    closeModal() {
      this.$refs['modal-create-personalization-entry'].hide();
      this.$emit('close-create-entry');
    },
    enterPressed() {
      if (!!this.name && !!this.id) {
        this.createPersonalizationEntry();
      }
    },
    idChangedByUser(id) {
      this.idChangedManually = true;
      this.id = this.checkForDuplicateSlugs(slugify(id));
    },
    nameUpdated(name) {
      if (!this.idChangedManually) {
        this.id = this.checkForDuplicateSlugs(slugify(name.toLowerCase()));
      }
    },
    checkForDuplicateSlugs(id) {
      // check if the slug is already in use
      if (this.clientKeys.includes(id)) {
        let newSlug;
        // find the right postfix
        for (let i = 1; i < 100; i++) {
          newSlug = `${id}-${i}`;
          if (!this.clientKeys.includes(newSlug)) {
            break;
          }
        }
        return newSlug;
      }
      return id;
    },
    onChange(event) {
      this.type = event.target.id;
      console.log('TYPE', this.type);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
